import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-services1',
  templateUrl: './page-services1.component.html',
  styleUrls: ['./page-services1.component.css'],
})
export class PageServices1Component implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle(
      'Range of gardening & landscaping services | Flarici'
    );
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Check out all our gardening and landscaping services. From design to maintenance, Flarici works on outdoor spaces with proper maintenance.',
    });
  }

  banner = {
    background: 'assets/images/banner/2.jpg',
    title: 'Services',
    crumb: 'Services',
  };

  services = {
    title: 'Welcome to Flarici',
    subtitle: 'Seasonal Garden Solutions Tailored for Your Unique Needs',
    services: [
      {
        title1: 'Soil fertilization and',
        title2: 'soil revitalization',
        description:
          'This service is very important for plant growth and improves soil structure',
        icon: 'assets/images/icons/1.png',
        count: '01',
        pageLink: 'soil-fertilization-and-soil-revitalization',
      },
      {
        title1: 'Mulching and ',
        title2: 'composting',
        description:
          'To enrich the soil and retain moisture, cover it with organic matter',
        icon: 'assets/images/icons/2.png',
        count: '02',
        pageLink: 'mulching-and-composting',
      },
      {
        title1: 'Pest and weed',
        title2: 'control',
        description:
          'Protect your plants from weeds and pests with our service',
        icon: 'assets/images/icons/3.png',
        count: '03',
        pageLink: 'pest-and-weed-control',
      },
      {
        title1: 'Seasonal cleanup',
        title2: 'and maintenance',
        description:
          'This service ensures optimal plant growth through proper maintenance',
        icon: 'assets/images/icons/4.png',
        count: '04',
        pageLink: 'seasonal-cleanup-and-maintenance',
      },
      {
        title1: 'Landscape design',
        title2: 'and planning',
        description:
          'Organizing and designing gardens for functional use and aesthetic appeal',
        icon: 'assets/images/icons/5.png',
        count: '05',
        pageLink: 'landscape-design-and-planning',
      },
      {
        title1: 'Tree and',
        title2: 'shrub pruning',
        description:
          'Trimming trees and shrubs for a better, structural, and vigorous appearance of your garden',
        icon: 'assets/images/icons/6.png',
        count: '06',
        pageLink: 'tree-and-shrub-pruning',
      },
    ],
  };

  plans = [
    {
      price: '29',
      plan: 'Basic',
      description:
        'Tempo sit amet ipsum dolor, consecter adipisi cing elit, lorem do incididu eiusmod tempor dolor',
    },
    {
      price: '49',
      plan: 'Standard',
      description:
        'Tempo sit amet ipsum dolor, consecter adipisi cing elit, lorem do incididu eiusmod tempor dolor',
    },
    {
      price: '99',
      plan: 'Advanced',
      description:
        'Tempo sit amet ipsum dolor, consecter adipisi cing elit, lorem do incididu eiusmod tempor dolor',
    },
  ];

  testimonials = {
    title: 'Our specialized service',
    subtitle: 'Comprehensive lawn and garden services',
    bgImage: 'assets/images/background/bg-map.png',
    bgClass: 'bg-center bg-gray',
    quotes: [
      {
        quote:
          'Our specialty is to provide a wide range of lawn and gardening services, including but not limited to fertilizing, pest control, mowing, pruning, and many more, to keep your garden well-maintained and beautiful year-round.',
        image: 'assets/images/testimonials/pic1.jpg',
        by: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
    ],
  };

  vision = {
    title: 'Our specialized service',
    subtitle: 'Comprehensive lawn and garden services',
    description:
      'Our specialty is to provide a wide range of lawn and gardening services, including but not limited to fertilizing, pest control, mowing, pruning, and many more, to keep your garden well-maintained and beautiful year-round.',
    videoLink: 'https://www.youtube.com/watch?v=fgExvIUYg5w',
    quote:
      'Many of Our SELF registered employees are requested an main preferred temporary staff when all service',
    quoteBy: 'Farnandoz Biki, CEO',
    // title2: 'Vision & Mission Statement',
    // subtitle2: 'Serving Impressive List Of Long Term Clients!',
    // description2:
    //   "Our professionalism and services across Hong Kong ensure timeliness, cost efficiency and compliance adherence required to ensure your timelines are met. Serving with experience and expertise in multiple industries, as one of the World's leading Corporation!",
    // title3: 'Vision & Mission Statement',
    // subtitle3: 'Serving Impressive List Of Long Term Clients!',
    // description3:
    //   "Our professionalism and services across Hong Kong ensure timeliness, cost efficiency and compliance adherence required to ensure your timelines are met. Serving with experience and expertise in multiple industries, as one of the World's leading Corporation!",
  };
}
