<div
  class="section-full self-intro-section-outer overlay-wraper {{
    data.bgCover
  }}"
  style="background-image:url({{ data.bgImage }})"
>
  <div class="overlay-main site-bg-secondry {{ data.opacity }}"></div>
  <div class="self-intro-top {{ data.bgClass }} p-t80 p-b50">
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-lg-6 col-md-12">
          <div class="left wt-small-separator-outer">
            <div class="wt-small-separator site-text-primary">
              <div class="sep-leaf-left"></div>
              <div>Reach out to us</div>
              <div class="sep-leaf-right"></div>
            </div>
            <h2>Landscaping with a Touch of Excellence</h2>
            <p>
              To receive our exemplary gardening and landscaping service, simply
              contact our customer support team with your requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="self-intro-bottom p-t80 p-b80">
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-lg-6 col-md-6">
          <div class="self-info-detail">
            <div class="wt-icon-box-wraper p-b10 left">
              <div class="icon-md m-b20">
                <span class="icon-cell site-text-primary"
                  ><i class="flaticon-call"></i
                ></span>
              </div>
              <div class="icon-content text-white">
                <h3 class="wt-tilte">Any question? call now</h3>
                <p>+852 64707116</p>
              </div>
            </div>

            <div class="wt-icon-box-wraper p-b10 left">
              <div class="icon-md m-b20">
                <span class="icon-cell site-text-primary"
                  ><i class="flaticon-mail"></i
                ></span>
              </div>
              <div class="icon-content text-white">
                <h3 class="wt-tilte">You may contact us at</h3>
                <p>info&#64;flarici.com</p>
              </div>
            </div>

            <div class="wt-icon-box-wraper left">
              <div class="icon-md m-b20">
                <span class="icon-cell site-text-primary">
                  <!-- <i class="flaticon-history"> </i -->
                </span>
              </div>
              <div class="icon-content text-white">
                <!-- <h3 class="wt-tilte">We are open on</h3>
                <p>Mon - Sat 07:00 - 21:00</p>
                <p>Sunday - Closed</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="{{ data.imageClass }}">
      <div class="wt-media"><img src="{{ data.image }}" alt="" /></div>
    </div>
  </div>
</div>
