import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-home1',
  templateUrl: './page-home1.component.html',
  styleUrls: ['./page-home1.component.css'],
})
export class PageHome1Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  about = {
    title: 'Welcome to Flarici',
    subtitle: 'We Are Here to Increase Your Knowledge With Experience',
    image: 'assets/images/colarge/s1.jpg',
    since: '2015',
    description:
      'Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit. a ballpark value added is activity to beta test. Override the digital divide with additional click throughs from fruit to identify a ballpark value added.',
    sign: 'assets/images/sign.png',
    authorized: 'Brayden',
    post: 'CEO & Founder',
    services: [
      {
        value: 'Quality Control System , 100% Satisfaction Guarantee',
      },
      {
        value: 'Unrivalled Workmanship, Professional and Qualified',
      },
      {
        value: 'Environmental Sensitivity, Personalised Solutions',
      },
    ],
  };

  services = {
    title: 'The Best Industry services',
    subtitle:
      'High Performance Services For Multiple Insdustries And Technologies!',
    description:
      'Progressively maintain extensive infomediaries via extensible niches. Capitalize on low hanging fruit to identify a ballpark value added is activity to beta test. Override the igital divide with additional click throughs from fruit to identify a ballpark value added.',
    services: [
      {
        title: 'Oil & Gas Engineering',
        description:
          'Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.',
        icon: 'flaticon-industry',
        serial: '01',
        pagelink: 's-oilgas',
      },
      {
        title: 'Mechanical Engineering',
        description:
          'Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.',
        icon: 'flaticon-conveyor',
        serial: '02',
        pagelink: 's-mechanical',
      },
      {
        title: 'Automotive Manufacturing',
        description:
          'Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.',
        icon: 'flaticon-robotic-arm',
        serial: '03',
        pagelink: 's-automotive',
      },
      {
        title: 'Chemical Research',
        description:
          'Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.',
        icon: 'flaticon-oil',
        serial: '04',
        pagelink: 's-chemical',
      },
      {
        title: 'Agricultural Automation',
        description:
          'Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.',
        icon: 'flaticon-scythe',
        serial: '05',
        pagelink: 's-agricultural',
      },
      {
        title: 'Civil Engineering',
        description:
          'Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.',
        icon: 'flaticon-helmet',
        serial: '06',
        pagelink: 's-civil',
      },
    ],
  };

  whatwedo = {
    title: 'What We do',
    subtitle: 'Providing Full Range of High Services Solution Worldwide',
    description:
      'Progressively maintain extensive infomediaries via extensible niches. Capitalize on low hanging fruit to identify a ballpark value added is activity to beta test. Override the igital divide',
    image: 'assets/images/what-we/pic1.jpg',
    experience: {
      title: 'Team Working Dedicatedly',
      description:
        'We have 26+ years of experience with providing wide area of specialty services works listed below',
    },
    work: [
      {
        icon: 'flaticon-robotic-arm',
        title: 'A Full Services',
        description:
          'We are Providing different services in this sector to wide area of world',
      },
      {
        icon: 'flaticon-repair',
        title: 'All Maintenance',
        description:
          'We are Prous to Protect your organization with our award-winning products',
      },
    ],
  };

  video = {
    title: 'All Solutions',
    subtitle: 'Get A Solution For All Industries',
    bgImage: 'assets/images/background/bg-2.jpg',
    bgOpacity: '09',
    bgColor: 'bg-white',
    videolink: 'https://www.youtube.com/watch?v=fgExvIUYg5w',
    solutions: [
      {
        count: '35',
        title: 'Projects Completed',
      },
      {
        count: '1435',
        title: 'Work Employed',
      },
      {
        count: '750',
        title: 'Work facilities',
      },
    ],
    description: {
      title: 'The Best Solution For all industries',
      subtitle:
        'Seving an impressive list of long-term clients with experience and expertise in industries.',
      quote:
        'Many of Our SELF registered employees are requested an main preferred temporary staff when all service',
      quoteby: 'Farnandoz Biki, CEO',
    },
  };

  projects = {
    title: 'Explore Recent Work',
    subtitle: "We've Done Lot's Projects Let's Check Some Here",
    description:
      'You will find yourself working in a partnership that result in an incredible Experience, and an end products that is the best',
    image: 'assets/images/crane.png',
    projects: [
      {
        title: 'Manufacturing & Maintenance',
        subtitle: 'Mechanical Engineering',
        description:
          'You will find yourself working that result partnership in an incredible Experience, and an end products that is the best',
        image: 'assets/images/project/pic1.jpg',
      },
      {
        title: 'Manufacturing & Maintenance',
        subtitle: 'Mechanical Engineering',
        description:
          'You will find yourself working that result partnership in an incredible Experience, and an end products that is the best',
        image: 'assets/images/project/pic2.jpg',
      },
      {
        title: 'Manufacturing & Maintenance',
        subtitle: 'Mechanical Engineering',
        description:
          'You will find yourself working that result partnership in an incredible Experience, and an end products that is the best',
        image: 'assets/images/project/pic3.jpg',
      },
      {
        title: 'Manufacturing & Maintenance',
        subtitle: 'Mechanical Engineering',
        description:
          'You will find yourself working that result partnership in an incredible Experience, and an end products that is the best',
        image: 'assets/images/project/pic4.jpg',
      },
    ],
  };

  team = {
    title: 'Our Experts',
    subtitle: 'We will serve you with the best of our capacity by expert team',
    boxWidth: 'col-lg-4',
    members: [
      {
        image: 'assets/images/team/pic1.jpg',
        name: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        image: 'assets/images/team/pic2.jpg',
        name: 'Froster Collings',
        post: 'Contractor',
      },
      {
        image: 'assets/images/team/pic3.jpg',
        name: 'Melena Marshall',
        post: 'Contractor',
      },
    ],
  };

  testimonials = {
    title: 'What our client say',
    subtitle: 'Happy WIth Customers & Clients',
    description:
      'I feel very happy and be proud to connect with this industry. i presume this is a very productive and professional industry.i wish very good luck & success for this industry',
    quotesTitle: 'Client says',
    quotes: [
      {
        image: 'assets/images/testimonials/pic1.jpg',
        quote:
          'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!',
        name: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        image: 'assets/images/testimonials/pic2.jpg',
        quote:
          'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!',
        name: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        image: 'assets/images/testimonials/pic3.jpg',
        quote:
          'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!',
        name: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
    ],
  };

  blogs = {
    title: 'Latest Articles Updated Daily',
    subtitle: 'We Are Here To Learn You More From Blog',
    blogs: [
      {
        image: 'assets/images/blog/default/thum1.jpg',
        field: 'Materials',
        date: 'July 28, 2021',
        comments: '24',
        title:
          'Technology is important for your business, you use technology makes',
      },
      {
        image: 'assets/images/blog/default/thum2.jpg',
        field: 'Technology',
        date: 'July 28, 2021',
        comments: '24',
        title:
          'Present you with examples of 3 posts from authority websites click',
      },
      {
        image: 'assets/images/blog/default/thum3.jpg',
        field: 'Manufacturing',
        date: 'July 28, 2021',
        comments: '24',
        title: 'Explain to you why they work and what makes people click them',
      },
    ],
  };

  clients = {
    bgcolor: 'bg-gray',
    clients: [
      'assets/images/client-logo/w1.png',
      'assets/images/client-logo/w2.png',
      'assets/images/client-logo/w3.png',
      'assets/images/client-logo/w4.png',
      'assets/images/client-logo/w5.png',
      'assets/images/client-logo/w6.png',
      'assets/images/client-logo/w1.png',
      'assets/images/client-logo/w2.png',
      'assets/images/client-logo/w3.png',
      'assets/images/client-logo/w4.png',
      'assets/images/client-logo/w5.png',
      'assets/images/client-logo/w6.png',
      'assets/images/client-logo/w1.png',
      'assets/images/client-logo/w2.png',
      'assets/images/client-logo/w3.png',
      'assets/images/client-logo/w4.png',
      'assets/images/client-logo/w5.png',
      'assets/images/client-logo/w6.png',
    ],
  };
}
