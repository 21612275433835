<!-- <div class="page-wraper"> -->
<!-- HEADER START -->
<app-section-header5></app-section-header5>
<!-- HEADER END -->

<!-- CONTENT START -->
<div class="page-content">
  <!-- INNER PAGE BANNER -->
  <app-section-inner-banner [data]="banner"></app-section-inner-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SERVICE DETAIL SECTION START -->
  <div class="section-full p-t80 p-b50 bg-white">
    <div class="section-content">
      <div class="container">
        <div class="wt-box services-etc m-b30">
          <div class="wt-media m-b30"
            style="display: flex; justify-content: center;"
          >
            <img src="assets/images/services/large/pic6.jpg" alt="" />
          </div>
          <div class="wt-info">
            <p>
              At Flarici, we offer reliable tree and shrub pruning services
              that will nourish your plants, keeping them well-shaped and
              attractive. Book our expert gardeners to remove overgrown and dead
              branches, helping maintain their natural beauty and encourage new
              growth. By promoting sunlight exposure and proper air circulation,
              our service helps your plants and shrubs thrive in a
              well-maintained landscape.
            </p>
          </div>
        </div>

        <div>
          <h2>FAQs</h2>
          <div class="wt-accordion acc-bg-gray" id="accordion5">
            <div class="panel wt-panel">
              <div class="acod-head acc-actives">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne5"
                    data-parent="#accordion5"
                  >
                    How frequently should shrubs and trees be pruned?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne5" class="acod-body collapse show bg-gray">
                <div class="acod-content p-a15">
                  Your trees and shrubs should be pruned according to their
                  growth rate. Experts generally recommend yearly pruning to
                  maintain a plant's shape, prevent potential hazards, and
                  encourage better growth.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseTwo5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    Is pruning harmful to my plants?<span class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseTwo5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  No, pruning is not harmful to your plants when done correctly.
                  It improves air circulation and promotes growth by removing
                  diseased and dead branches.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseThree5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    Can you prune our plants during any season?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseThree5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  Depending on the species of your plants, we will determine the
                  better season for pruning. For many species, early to late
                  spring is ideal for pruning.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FOOTER START -->
<app-section-footer1></app-section-footer1>
<!-- FOOTER END -->
<!-- </div> -->
