<footer class="site-footer footer-large footer-dark text-white footer-style2">
  <!-- FOOTER BLOCKES START -->
  <div
    class="footer-top bg-no-repeat bg-bottom-right"
    style="background-image: url(assets/images/background/footer-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div class="footer-h-left">
            <div class="widget widget_about">
              <div class="logo-footer clearfix">
                <a routerLink="/index"
                  ><img
                    id="footerLogo2"
                    src="assets/images/logo-9-light.png"
                    alt=""
                /></a>
              </div>
              <p>
                Over 15 year experience and knowledge international standards
                technologicaly changes our industrial systems, we are dedicated
                to provides the best solutions to our valued customers there are
                many variation solution we makes long-term investments goal in
                global companies in different sectors, mainly in USA and other
                countries
              </p>
            </div>
            <div class="widget recent-posts-entry">
              <ul class="widget_address">
                <li>
                  <i class="fa fa-map-marker"></i>40 Flower Market Rd, Prince Edward, Hong Kong
                </li>
                <li><i class="fa fa-envelope"></i>contact123@gmail.com</li>
                <li><i class="fa fa-phone"></i>+852 64707116</li>
              </ul>
            </div>
            <ul class="social-icons wt-social-links footer-social-icon">
              <li>
                <a href="https://www.google.com/" class="fa fa-google"></a>
              </li>
              <li><a href="https://rss.com/" class="fa fa-rss"></a></li>
              <li>
                <a href="https://www.facebook.com/" class="fa fa-facebook"></a>
              </li>
              <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
              <li>
                <a href="https://www.linkedin.com/" class="fa fa-linkedin"></a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-7 col-md-12 col-sm-12">
          <div class="row footer-h-right">
            <div class="col-lg-5 col-md-4">
              <div class="widget widget_services">
                <h3 class="widget-title">Useful links</h3>
                <ul>
                  <li><a routerLink="/about/about1">About</a></li>
                  <li><a routerLink="/other/our-prices">Pricing Plan </a></li>
                  <li><a routerLink="/other/faq">FAQ</a></li>
                  <li><a routerLink="/other/team-single">Our Team </a></li>
                  <li><a routerLink="/services/services1">Services </a></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-7 col-md-8">
              <div class="widget widget_services">
                <h3 class="widget-title">Our Services</h3>
                <ul>
                  <li>
                    <a routerLink="/services/s-oilgas">Oil & Gas Factory</a
                    ><a routerLink="/services/s-chemical">Chemical Research</a>
                  </li>
                  <li>
                    <a routerLink="/services/s-chemical">Chemical Research</a
                    ><a routerLink="/services/s-agricultural">Agricultural</a>
                  </li>
                  <li>
                    <a routerLink="/services/s-mechanical">Mechanical</a
                    ><a routerLink="/services/s-agricultural">Agricultural </a>
                  </li>
                  <li>
                    <a routerLink="/services/s-civil">Manufacturing</a
                    ><a routerLink="/services/s-civil">Civil Engineering</a>
                  </li>
                  <li>
                    <a routerLink="/services/s-automotive">Mechanical </a
                    ><a routerLink="/services/s-automotive">Auto Motive </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="widget widget_newsletter">
            <h3 class="widget-title">Newsletter</h3>
            <p>
              Subscribe to our newsletter to receive latest news on our
              services.
            </p>
            <div class="newsletter-input">
              <div class="input-group">
                <input
                  id="email"
                  type="text"
                  class="form-control"
                  name="email"
                  placeholder="Enter your email"
                />
                <div class="input-group-append">
                  <button
                    type="submit"
                    class="input-group-text nl-search-btn text-black site-bg-primary title-style-2"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FOOTER COPYRIGHT -->

  <div class="footer-bottom">
    <div class="container">
      <div class="wt-footer-bot-left d-flex justify-content-between">
        <span class="copyrights-text"
          >Copyright © 2021
          <span class="site-text-primary">Thewebmax</span></span
        >
        <ul class="copyrights-nav">
          <li><a routerLink="/about/about1">Terms &amp; Condition</a></li>
          <li><a routerLink="/about/about2">Privacy Policy</a></li>
          <li><a routerLink="/other/contact1">Contact Us</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
