<footer class="site-footer footer-large footer-dark text-white footer-style1">
  <!-- FOOTER BLOCKES START -->
  <div
    class="footer-top bg-no-repeat bg-bottom-right"
    style="background-image: url(assets/images/background/footer-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div class="footer-h-left">
            <div class="widget widget_about">
              <div class="logo-footer clearfix">
                <a href="/"
                  ><img
                    id="footerLogo1"
                    src="assets/images/logo-light.png"
                    alt=""
                /></a>
              </div>
              <p>
                Want to turn your dream garden into reality? Flarici transforms
                your barren paradise into a full-fledged sanctuary with their
                expert landscaping services. From seasonal maintenance to custom
                designs, we create vibrant, lush gardens according to your
                needs. Trust our team of gardeners for soil revitalization, pest
                control, and more.
              </p>
            </div>
            <!-- <div class="widget recent-posts-entry">
              <ul class="widget_address">
                <li>
                  <i class="fa fa-map-marker"></i>San Francisco City Hall, San
                  Francisco, CA
                </li>
                <li><i class="fa fa-envelope"></i>contact123&#64;gmail.com</li>
                <li><i class="fa fa-phone"></i>+852 64707116</li>
              </ul>
            </div>
            <ul class="social-icons wt-social-links footer-social-icon">
              <li>
                <a href="https://www.google.com/" class="fa fa-google"></a>
              </li>
              <li><a href="https://rss.com/" class="fa fa-rss"></a></li>
              <li>
                <a href="https://www.facebook.com/" class="fa fa-facebook"></a>
              </li>
              <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
              <li>
                <a href="https://www.linkedin.com/" class="fa fa-linkedin"></a>
              </li>
            </ul> -->
          </div>
        </div>

        <div class="col-lg-7 col-md-12 col-sm-12">
          <div class="row footer-h-right">
            <div class="col-lg-4 col-md-4">
              <div class="widget widget_services">
                <h3 class="widget-title">Quick links</h3>
                <ul>
                  <li><a routerLink="/">Home</a></li>
                  <li><a routerLink="/about">About Us</a></li>
                  <li><a routerLink="/services">Services</a></li>
                  <li><a routerLink="/blogs">Blogs</a></li>
                  <li><a routerLink="/faqs">FAQs</a></li>
                  <li><a routerLink="/contact">Contact Us</a></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="widget widget_services">
                <h3 class="widget-title">Other Links</h3>
                <ul>
                  <li>
                    <a routerLink="/cookies-policy">Cookies Policy</a>
                  </li>
                  <li>
                    <a routerLink="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a routerLink="/terms-and-conditions">Terms & Conditions</a>
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="widget recent-posts-entry">
                <h3 class="widget-title">Contact Us</h3>
                <ul class="widget_address">
                  <li>
                    <i class="fa fa-map-marker"></i>40 Flower Market Rd, Prince
                    Edward, Hong Kong
                  </li>
                  <li><i class="fa fa-envelope"></i>info&#64;flarici.com</li>
                  <li><i class="fa fa-phone"></i>+852 64707116</li>
                </ul>
              </div>
              <ul class="social-icons wt-social-links footer-social-icon">
                <li>
                  <a
                    href="https://www.pinterest.com/Flaricihk/"
                    target="_blank"
                    class="fa fa-pinterest"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://x.com/Flaricihk"
                    target="_blank"
                    class="fa fa-twitter"
                  ></a>
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="widget widget_newsletter">
            <h3 class="widget-title">Newsletter</h3>
            <p>
              Subscribe to our newsletter to receive latest news on our
              services.
            </p>
            <div class="newsletter-input">
              <div class="input-group">
                <input
                  id="email"
                  type="text"
                  class="form-control"
                  name="email"
                  placeholder="Enter your email"
                />
                <div class="input-group-append">
                  <button
                    type="submit"
                    class="input-group-text nl-search-btn text-black site-bg-primary title-style-2"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- FOOTER COPYRIGHT -->

  <div class="footer-bottom">
    <div class="container">
      <div class="wt-footer-bot-left d-flex justify-content-between">
        <span class="copyrights-text"
          >Copyright © 2024
          <a routerLink="/" style="color: #ff5e15">Flarici</a>. All Right
          Reserved.</span
        >
      </div>
    </div>
  </div>
</footer>
