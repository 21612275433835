import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-contact-us',
  templateUrl: './page-contact-us.component.html',
  styleUrls: ['./page-contact-us.component.css'],
})
export class PageContactUsComponent implements OnInit {
  formData = {
    username: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };
  isSubmitting = false;
  isMessage = false;

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle('Get in touch with us | Flarici');
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Contact Flarici for professional gardening and landscaping services. We are ready to assist you with all your queries.',
    });
  }
  banner = {
    background: 'assets/images/banner/5.jpg',
    title: 'Contact Us',
    crumb: 'Contact Us',
  };

  onSubmit(form: NgForm): void {
    if (!form.valid) {
      // console.log('Form is invalid. Marking all fields as touched.');
      Object.keys(form.controls).forEach((field) => {
        const control = form.controls[field];
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.isSubmitting = true;

    // console.log('Form is valid. Proceeding with submission.');
    const apiUrl = 'https://acomal.top/send-mail/64/';
    this.http.post(apiUrl, this.formData).subscribe(
      (response) => {
        // console.log('Email sent successfully!', response);
        // Reset form data after successful submission
        this.isMessage = true;
        this.formData = {
          username: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        };
        form.resetForm(); // Resets the form and clears validation
        this.isSubmitting = false;
      },
      (error) => {
        // console.error('Error sending email:', error);
        alert('There was an error sending your message. Please try again.');
        this.isSubmitting = false;
      }
    );
  }
}
