import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageError404Component } from './pages/others/page-error404/page-error404.component';
import { PageContactUsComponent } from './pages/others/page-contact-us/page-contact-us.component';
// import { PageProductsComponent } from './pages/shop/page-products/page-products.component';
import { PageBlogListComponent } from './pages/blog/page-blog-list/page-blog-list.component';
import { PageBlogPostComponent } from './pages/blog/page-blog-post/page-blog-post.component';
import { PageBlogPostComponent2 } from './pages/blog/page-blog-post2/page-blog-post.component';
import { PageSoilFertilization } from './pages/services/soil-fertilization/page-s-agricultural.component';
import { PageLandscapedesign } from './pages/services/landscape-design-and-planning/page-s-mechanical.component';
import { PageMulchingandcomposting } from './pages/services/mulching-and-composting/page-s-automotive.component';
import { PagePestandweed } from './pages/services/pest-and-weed-control/page-s-chemical.component';
import { PageSeasonalcleanup } from './pages/services/seasonal-cleanup-and-maintenance/page-s-civil.component';
import { PageTreeandshrubpruning } from './pages/services/tree-and-shrub-pruning/page-s-oilgas.component';
import { Pagecookiespolicy } from './pages/others/cookies-policy/page-faq.component';
import { Pageprivacypolicy } from './pages/others/privacy-policy/page-faq.component';
import { Pagetermsandconditions } from './pages/others/terms-and-conditions/page-faq.component';
import { PageFaqComponent } from './pages/others/page-faq/page-faq.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./modules/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./modules/services/services.module').then(
        (m) => m.ServicesModule
      ),
  },
  {
    path: 'faqs',
    component: PageFaqComponent,
  },
  {
    path: 'terms-and-conditions',
    component: Pagetermsandconditions,
  },
  {
    path: 'privacy-policy',
    component: Pageprivacypolicy,
  },
  {
    path: 'cookies-policy',
    component: Pagecookiespolicy,
  },
  {
    path: 'tree-and-shrub-pruning',
    component: PageTreeandshrubpruning,
  },
  {
    path: 'seasonal-cleanup-and-maintenance',
    component: PageSeasonalcleanup,
  },
  {
    path: 'pest-and-weed-control',
    component: PagePestandweed,
  },
  {
    path: 'soil-fertilization-and-soil-revitalization',
    component: PageSoilFertilization,
  },
  {
    path: 'landscape-design-and-planning',
    component: PageLandscapedesign,
  },
  {
    path: 'mulching-and-composting',
    component: PageMulchingandcomposting,
  },
  {
    path: 'blogs',
    loadChildren: () =>
      import('./modules/blog/blog.module').then((m) => m.BlogModule),
  },
  { path: 'blog-list-sidebar', component: PageBlogListComponent },
  {
    path: 'the-mental-and-physical-benefits-of-gardening',
    component: PageBlogPostComponent,
  },
  {
    path: 'the-role-of-hardscaping-in-beautiful-landscape-design',
    component: PageBlogPostComponent2,
  },
  {
    path: 'contact',
    component: PageContactUsComponent,
  },
  { path: '**', component: PageError404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
