import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-s-automotive',
  templateUrl: './page-s-automotive.component.html',
  styleUrls: ['./page-s-automotive.component.css'],
})
export class PageMulchingandcomposting implements OnInit {
  @Input() loadingurl: any;

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.loadingurl = event.url.split('/')[2];
      });
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle('Our mulching and composting | Flarici');
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Our mulching and composting services conserve water, improve soil texture, and help create a flourishing outdoor space.',
    });
  }

  banner = {
    background: 'assets/images/banner/2.jpg',
    title: 'Mulching and composting',
    crumb: 'Mulching and composting',
  };
}
