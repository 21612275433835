<!-- <div class="page-wraper"> -->
<!-- HEADER START -->
<app-section-header5></app-section-header5>
<!-- HEADER END -->

<!-- CONTENT START -->
<div class="page-content">
  <!-- INNER PAGE BANNER -->
  <app-section-inner-banner [data]="banner"></app-section-inner-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SERVICE DETAIL SECTION START -->
  <div class="section-full p-t80 p-b50 bg-white">
    <div class="section-content">
      <div class="container">
        <div class="wt-box services-etc m-b30">
          <div
            class="wt-media m-b30"
            style="display: flex; justify-content: center;"
          >
            <img src="assets/images/services/large/pic1.jpg" alt="" />
          </div>
          <div class="wt-info">
            <p>
              To promote the growth of your plants, check out our fertilization
              and soil revitalization services, enriching your soil with
              essential nutrients. Our group of expert gardeners will provide
              tailored solutions to boost plant vitality and improve soil
              quality, and increase root development. We will help you to create
              a solid foundation for a thriving, lush garden that remains
              beautiful in every season.
            </p>
          </div>
        </div>

        <div>
          <h2>FAQs</h2>
          <div class="wt-accordion acc-bg-gray" id="accordion5">
            <div class="panel wt-panel">
              <div class="acod-head acc-actives">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne5"
                    data-parent="#accordion5"
                  >
                    Why is soil revitalization effective for my garden?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne5" class="acod-body collapse show bg-gray">
                <div class="acod-content p-a15">
                  Soil revitalization effectively maintains soil structure, pH
                  levels, and nutrient content, helping plants thrive in every
                  circumstances.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseTwo5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    How often should the fertilization be done?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseTwo5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  Fertilization should be done 2-4 times in a year, depending on
                  the soil conditions and plant type. Based on the specific
                  needs of your garden, our experts recommend a convenient
                  schedule.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseThree5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    Is fertilizer you use safe for the environment?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseThree5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  Yes, we use eco-friendly soil revitalization solutions and
                  fertilizers that are completely safe for the environment and
                  plants.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FOOTER START -->
<app-section-footer1></app-section-footer1>
<!-- FOOTER END -->
<!-- </div> -->
