<div
  class="section-full p-t80 p-b50 bg-white bg-bottom-right bg-no-repeat"
  style="background-image: url(assets/images/background/bg-5.png)"
>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-12 col-md-12 m-b30">
        <!-- TITLE START-->
        <div class="left wt-small-separator-outer">
          <div class="wt-small-separator site-text-primary">
            <div class="sep-leaf-left"></div>
            <div>{{ data.title }}</div>
            <div class="sep-leaf-right"></div>
          </div>
          <h2>{{ data.subtitle }}</h2>
          <p>{{ data.description }}</p>
          <h4>
            <strong><em>Expert Knowledge</em></strong>
          </h4>
          <p>
            Each member in our gardening support team has in-depth knowledge and
            experience handling gardening tasks.
          </p>
          <h4>
            <strong
              ><em
                ><strong><em>Personalized Approach</em></strong></em
              ></strong
            >
          </h4>
          <p>
            We fulfill the specific preferences and needs of each customer with
            our tailored services, ensuring a thriving garden.
          </p>
          <h4>
            <strong
              ><em
                ><strong><em>Quality Results</em></strong></em
              ></strong
            >
          </h4>
          <p>
            By using reliable techniques, tools, and quality plants, our
            experienced staff deliver exceptional results for your garden.
          </p>
          <h4>
            <strong
              ><em
                ><strong><em>Time-Saving</em></strong></em
              ></strong
            >
          </h4>
          <p>
            We handle all the intricate requirements for a flourishing garden,
            so you can enjoy the perfect visuals of your blooming space.
          </p>
          <h4>
            <strong
              ><em
                ><strong><em>Eco-Friendly Practices</em></strong></em
              ></strong
            >
          </h4>
          <p>
            We maintain eco-friendly and sustainable practices that safeguard
            both the environment and your garden with minimal effort.
          </p>
          <h4>
            <strong
              ><em
                ><strong><em>Affordable Pricing</em></strong></em
              ></strong
            >
          </h4>
          <p>
            Get quality gardening services from our expert gardeners at your
            convenience and without breaking the bank.
          </p>
        </div>
        <!-- TITLE END-->
      </div>
    </div>
  </div>
</div>
