import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-home5',
  templateUrl: './page-home5.component.html',
  styleUrls: ['./page-home5.component.css'],
})
export class PageHome5Component implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle(
      'Your expert gardening & landscaping company| Flarici'
    );
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Find high-quality gardening and landscaping services here at Flarici. We design beautiful and sustainable outdoor spaces.',
    });
  }

  services = {
    title: 'Welcome to Flarici',
    subtitle: 'Your one-stop destination for innovative landscape development',
    services: [
      {
        image: 'assets/images/gallery/portrait-3/pic1.jpg',
        detailImage: 'assets/images/gallery/portrait-2/pic1.jpg',
        type: 'Soil Care',
        description:
          'Improve soil strength and plant growth with our soil revitalization solutions that promote a vibrant and strong landscape.',
        title: 'Fertilization and soil revitalization',
        pageLink: 'soil-fertilization-and-soil-revitalization',
      },
      {
        image: 'assets/images/gallery/portrait-3/pic2.jpg',
        detailImage: 'assets/images/gallery/portrait-2/pic2.jpg',
        type: 'Soil Enrichment',
        description:
          'Boost moisture retention and fertility of your soil with this service that adds nutrient-rich compost and mulch to nourish your plants.',
        title: 'Mulching and composting',
        pageLink: 'mulching-and-composting',
      },
      {
        image: 'assets/images/gallery/portrait-3/pic3.jpg',
        detailImage: 'assets/images/gallery/portrait-2/pic3.jpg',
        type: 'Protection',
        description:
          'We use eco-friendly practice to safeguard your plants from invasive weeds and pests, ensuring longevity of plants.',
        title: 'Pest and weed control',
        pageLink: 'pest-and-weed-control',
      },
      {
        image: 'assets/images/gallery/portrait-3/pic4.jpg',
        detailImage: 'assets/images/gallery/portrait-2/pic4.jpg',
        type: 'Seasonal Care',
        description:
          'With our seasonal clean-ups, you can keep your garden pristine all year long and prepare your garden for every future challenge.',
        title: 'Seasonal cleanup and maintenance',
        pageLink: 'seasonal-cleanup-and-maintenance',
      },
      {
        image: 'assets/images/gallery/portrait-3/pic5.jpg',
        detailImage: 'assets/images/gallery/portrait-2/pic5.jpg',
        type: 'Design',
        description:
          'Craft functional and personalized outdoor space with our landscape planning and design service that boosts usability and beauty of your garden.',
        title: 'Landscape design and planning',
        pageLink: 'landscape-design-and-planning',
      },
      {
        image: 'assets/images/gallery/portrait-3/pic6.jpg',
        detailImage: 'assets/images/gallery/portrait-2/pic6.jpg',
        type: 'Pruning',
        description:
          'To receive our exemplary gardening and landscaping service, simply contact our customer support team with your requirements.',
        title: 'Tree and shrub pruning',
        pageLink: 'tree-and-shrub-pruning',
      },
    ],
  };

  video = {
    title: 'All Solutions',
    subtitle: 'Get A Solution For All Industries',
    bgImage: 'assets/images/background/bg-15.jpg',
    bgOpacity: '05',
    bgColor: 'bg-white',
    videolink: 'https://www.youtube.com/watch?v=fgExvIUYg5w',
    solutions: [
      {
        count: '35',
        title: 'Projects Completed',
      },
      {
        count: '1435',
        title: 'Work Employed',
      },
      {
        count: '750',
        title: 'Work facilities',
      },
    ],
    description: {
      title: 'The Best Solution For all industries',
      subtitle:
        'Seving an impressive list of long-term clients with experience and expertise in industries.',
      quote:
        'Many of Our SELF registered employees are requested an main preferred temporary staff when all service',
      quoteby: 'Farnandoz Biki, CEO',
    },
  };

  projects = {
    title: 'Our Projects',
    subtitle: 'Our Latest Works',
    projects: [
      {
        image: 'assets/images/project_3/pic1.jpg',
        title: 'Modren Garden',
        description:
          'Progressively maintain extensive infomediaries via extensible nich.',
      },
      {
        image: 'assets/images/project_3/pic2.jpg',
        title: 'Wooden Decks',
        description:
          'Progressively maintain extensive infomediaries via extensible nich.',
      },
      {
        image: 'assets/images/project_3/pic3.jpg',
        title: 'Leaf Cutting',
        description:
          'Progressively maintain extensive infomediaries via extensible nich.',
      },
      {
        image: 'assets/images/project_3/pic4.jpg',
        title: 'Lawn Mower',
        description:
          'Progressively maintain extensive infomediaries via extensible nich.',
      },
      {
        image: 'assets/images/project_3/pic5.jpg',
        title: 'Parking Cleaning',
        description:
          'Progressively maintain extensive infomediaries via extensible nich.',
      },
      {
        image: 'assets/images/project_3/pic6.jpg',
        title: 'Design Planting',
        description:
          'Progressively maintain extensive infomediaries via extensible nich.',
      },
    ],
  };

  testimonials = {
    title: 'What our client say',
    subtitle: 'Happy WIth Customers & Clients',
    bgImage: 'assets/images/background/bg-13.jpg',
    bgClass: 'bg-center bg-gray',
    quotes: [
      {
        quote:
          'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!',
        image: 'assets/images/testimonials/pic1.jpg',
        by: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        quote:
          'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!',
        image: 'assets/images/testimonials/pic2.jpg',
        by: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        quote:
          'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!',
        image: 'assets/images/testimonials/pic3.jpg',
        by: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        quote:
          'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!',
        image: 'assets/images/testimonials/pic4.jpg',
        by: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
    ],
  };

  team = {
    title: 'Our Experts',
    subtitle: 'We will serve you with the best of our capacity by expert team',
    bgImage: '',
    members: [
      {
        image: 'assets/images/team-5/pic1.jpg',
        name: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        image: 'assets/images/team-5/pic2.jpg',
        name: 'Froster Collings',
        post: 'Contractor',
      },
      {
        image: 'assets/images/team-5/pic3.jpg',
        name: 'Melena Marshall',
        post: 'Contractor',
      },
    ],
  };

  selfIntro = {
    image: 'assets/images/self-pic-2.png',
    bgImage: 'assets/images/background/bg-14.jpg',
    bgClass: 'bg-white',
    bgCover: 'bg-cover',
    opacity: 'opacity-07',
    imageClass: 'self-intro-pic-block2',
  };

  blogs = {
    title: 'Our blogs',
    subtitle: 'Learn more about gardening and landscaping through this segment',
    articles: [
      {
        image: 'assets/images/blog/default-5/thum1.jpg',
        type: 'Materials',
        date: 'February 28, 2021',
        comments: '24',
        title: 'The Mental and Physical Benefits of Gardening',
        url: '/the-mental-and-physical-benefits-of-gardening',
      },
      {
        image: 'assets/images/blog/default-5/thum2.jpg',
        type: 'Technology',
        date: 'February 28, 2021',
        comments: '24',
        title: 'The Role of Hardscaping in Beautiful Landscape Design',
        url: '/the-role-of-hardscaping-in-beautiful-landscape-design',
      },
    ],
  };

  clients = {
    bgcolor: 'bg-gray',
    clients: [
      'assets/images/client-logo2/w1.png',
      'assets/images/client-logo2/w2.png',
      'assets/images/client-logo2/w3.png',
      'assets/images/client-logo2/w4.png',
      'assets/images/client-logo2/w5.png',
      'assets/images/client-logo2/w6.png',
      'assets/images/client-logo2/w1.png',
      'assets/images/client-logo2/w2.png',
      'assets/images/client-logo2/w3.png',
      'assets/images/client-logo2/w4.png',
      'assets/images/client-logo2/w5.png',
      'assets/images/client-logo2/w6.png',
    ],
  };
}
