<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header5></app-section-header5>
  <!-- HEADER END -->
  <!-- CONTENT START -->
  <div class="page-content">
    <!-- Error SECTION START -->
    <div
      class="error-full-page bg-cover overlay-wraper"
      style="background-image: url(assets/images/background/bg-3.jpg)"
    >
      <div class="overlay-main bg-black opacity-07"></div>

      <div class="error-full-page-inner">
        <div class="error-full-page-inner-info">
          <div class="error-media">
            <img src="assets/images/error/404.png" alt="" />
          </div>
          <!-- <h3 class="title-style-2">Error</h3>
          <strong>404</strong> -->
          <h4 class="title-style-2">
            <img
              src="assets/images/error/404-sep.png"
              alt=""
              class="e-left-img"
            />
            Oops! Looks like the page is gone.
            <img
              src="assets/images/error/404-sep.png"
              alt=""
              class="e-right-img"
            />
          </h4>
          <p>
            The page you are trying to visit doesn't exist or has been removed, please check and try again.
          </p>
          <a routerLink="/" class="site-button site-btn-effect">GO BACk</a>
        </div>
      </div>
    </div>
    <!-- Error  SECTION END -->
  </div>
</div>
<!-- FOOTER START -->
<app-section-footer1></app-section-footer1>
<!-- FOOTER END -->

<app-loader></app-loader>
