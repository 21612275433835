<!-- <div class="page-wraper"> -->
<!-- HEADER START -->
<app-section-header5></app-section-header5>
<!-- HEADER END -->

<!-- CONTENT START -->
<div class="page-content">
  <!-- INNER PAGE BANNER -->
  <app-section-inner-banner [data]="banner"></app-section-inner-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SERVICE DETAIL SECTION START -->
  <div class="section-full p-t80 p-b50 bg-white">
    <div class="section-content">
      <div class="container">
        <div class="wt-box services-etc m-b30">
          <div class="wt-media m-b30"
            style="display: flex; justify-content: center;"
          >
            <img src="assets/images/services/large/pic3.jpg" alt="" />
          </div>
          <div class="wt-info">
            <p>
              Want to boost soil structure and promote the growth of your
              plants? Try our exclusive mulching and composting service.
              Mulching effectively regulates soil temperature, retains moisture,
              and removes weeds. Create a sustainable, restored environment for
              your garden with these effective and eco-friendly practices.
              Composting is a practical way to recycle yard trimmings and food
              scraps generated at home throughout the year, helping you manage
              waste more sustainably.
            </p>
          </div>
        </div>

        <div>
          <h2>FAQs</h2>
          <div class="wt-accordion acc-bg-gray" id="accordion5">
            <div class="panel wt-panel">
              <div class="acod-head acc-actives">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne5"
                    data-parent="#accordion5"
                  >
                    Why does my garden need mulching?<span class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne5" class="acod-body collapse show bg-gray">
                <div class="acod-content p-a15">
                  Mulching helps regulate temperature, retain soil moisture,
                  improve soil quality, and prevent the growth of weeds.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseTwo5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    What materials are used for composting and mulching?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseTwo5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  We use all eco-friendly materials for composting and mulching
                  including straw, wood chips, and natural compost, for the
                  betterment of your environment and garden.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseThree5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    How often should mulching be done?<span class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseThree5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  Mulching is recommended once or twice a year, depending on
                  your plant's requirements and climate, to improve your garden
                  condition.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FOOTER START -->
<app-section-footer1></app-section-footer1>
<!-- FOOTER END -->
<!-- </div> -->
