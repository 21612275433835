<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header5></app-section-header5>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- SECTION CONTENTG START -->

    <!-- CONTACT FORM -->
    <div
      class="section-full p-t80 p-b50 bg-cover"
      style="background-image: url(assets/images/background/bg-7.jpg)"
    >
      <div class="section-content">
        <div class="container">
          <div class="contact-one">
            <!-- CONTACT FORM-->
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-6 col-md-6 m-b30">
                <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
                  <!-- Name Input -->
                  <div class="form-group">
                    <input
                      type="text"
                      name="username"
                      class="form-control"
                      placeholder="Name"
                      [(ngModel)]="formData.username"
                      required
                      #username="ngModel"
                    />
                    <div
                      *ngIf="
                        username.invalid &&
                        (username.touched || contactForm.submitted)
                      "
                      class="error-message"
                    >
                      Name is required.
                    </div>
                  </div>

                  <!-- Email Input -->
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="Email"
                      [(ngModel)]="formData.email"
                      required
                      email
                      #email="ngModel"
                    />
                    <div
                      *ngIf="
                        email.invalid &&
                        (email.touched || contactForm.submitted)
                      "
                      class="error-message"
                    >
                      Email is required and must be valid.
                    </div>
                  </div>

                  <!-- Phone Input -->
                  <div class="form-group">
                    <input
                      type="tel"
                      name="phone"
                      class="form-control"
                      placeholder="Phone"
                      [(ngModel)]="formData.phone"
                      required
                      #phone="ngModel"
                    />
                    <div
                      *ngIf="
                        phone.invalid &&
                        (phone.touched || contactForm.submitted)
                      "
                      class="error-message"
                    >
                      Phone is required.
                    </div>
                  </div>

                  <!-- Subject Input -->
                  <div class="form-group">
                    <input
                      type="text"
                      name="subject"
                      class="form-control"
                      placeholder="Subject"
                      [(ngModel)]="formData.subject"
                      required
                      #subject="ngModel"
                    />
                    <div
                      *ngIf="
                        subject.invalid &&
                        (subject.touched || contactForm.submitted)
                      "
                      class="error-message"
                    >
                      Subject is required.
                    </div>
                  </div>

                  <!-- Message Input -->
                  <div class="form-group">
                    <textarea
                      name="message"
                      class="form-control"
                      placeholder="Message"
                      [(ngModel)]="formData.message"
                      required
                      #message="ngModel"
                    ></textarea>
                    <div
                      *ngIf="
                        message.invalid &&
                        (message.touched || contactForm.submitted)
                      "
                      class="error-message"
                    >
                      Message is required.
                    </div>
                  </div>

                  <!-- Submit Button -->
                  <button
                    type="submit"
                    class="site-button site-btn-effect"
                    [disabled]="isSubmitting"
                  >
                    <span *ngIf="isSubmitting">Sending...</span>
                    <span *ngIf="!isSubmitting">Submit</span>
                  </button>
                </form>
                <span
                  *ngIf="isMessage == true"
                  style="
                    font-weight: bolder;
                    font-family: 'Teko', sans-serif;
                    font-size: 25px;
                    color: green;
                  "
                  >Message Sent Successfully!</span
                >
              </div>

              <div class="col-lg-6 col-md-6 m-b30">
                <div class="contact-info">
                  <div class="contact-info-inner">
                    <!-- TITLE START-->
                    <div class="section-head left wt-small-separator-outer">
                      <div class="wt-small-separator site-text-primary">
                        <div class="sep-leaf-left"></div>
                        <div>Contact info</div>
                        <div class="sep-leaf-right"></div>
                      </div>
                    </div>
                    <!-- TITLE END-->

                    <div
                      class="contact-info-section"
                      style="
                        background-image: url(assets/images/background/bg-map2.png);
                      "
                    >
                      <div class="wt-icon-box-wraper left m-b30">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">Phone number</h3>
                          <p>+852 64707116</p>
                        </div>
                      </div>

                      <div class="wt-icon-box-wraper left m-b30">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">Email address</h3>
                          <p>info&#64;flarici.com</p>
                        </div>
                      </div>

                      <div class="wt-icon-box-wraper left m-b30">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">Address info</h3>
                          <p>40 Flower Market Rd, Prince Edward, Hong Kong</p>
                        </div>
                      </div>

                      <!-- <div class="wt-icon-box-wraper left">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">Opening Hours</h3>
                          <ul class="list-unstyled m-b0">
                            <li>Mon-Fri: 9 am – 6 pm</li>
                            <li>Saturday: 9 am – 4 pm</li>
                            <li>Sunday: Closed</li>
                          </ul>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- GOOGLE MAP -->
    <div class="section-full bg-white">
      <div class="section-content">
        <div class="gmap-outline">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.6719511228614!2d114.17226566548665!3d22.325246677168554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400c95f3f7da9%3A0x1efa5120e56226a!2s40%20Flower%20Market%20Rd%2C%20Prince%20Edward%2C%20Hong%20Kong!5e0!3m2!1sen!2sin!4v1734167691567!5m2!1sen!2sin"
            width="100%"
            height="500"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>
