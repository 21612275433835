<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header5></app-section-header5>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- ABOUT SECTION START -->
        <app-section-about2 [data]="about"></app-section-about2>  
        <!-- ABOUT SECTION  SECTION END --> 
                                              
        <!-- ALL SERVICES START -->
        <!-- <app-section-services11 [data]="services"></app-section-services11> -->
        <!-- ALL SERVICES SECTION END --> 
        
         <!-- VIDEO SECTION START -->
        <!-- <app-section-video6 [data]="video"></app-section-video6> -->
        <!-- VIDEO SECTION  SECTION END -->              
        
        <!-- OUR TEAM START -->
        <!-- <app-section-team2 [data]="team" bgcover="0" grayBox="0"></app-section-team2>    -->
        <!-- OUR TEAM SECTION END --> 
                   
        <!-- CLIENT LOGO SECTION START -->
        <!-- <app-section-clients1 [data]="clients"></app-section-clients1> -->
        <!-- CLIENT LOGO  SECTION End -->                
        <app-section-vision1 [data]="vision"></app-section-vision1>
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
    
</div>
