<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header5></app-section-header5>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- SECTION CONTENT START -->
    <div class="section-full p-t80 p-b50">
      <div class="container">
        <div class="section-content">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-b30">
              <div class="row">
                <!-- COLUMNS 1 -->
                <div
                  class="col-lg-4 col-md-6 m-b30"
                  *ngFor="let object of products"
                >
                  <div class="wt-box wt-product-box block-shadow overflow-hide">
                    <div class="wt-thum-bx wt-img-overlay1 wt-img-effect zoom">
                      <img src="{{ object.image }}" alt="" />
                      <div class="overlay-bx">
                        <div class="overlay-icon">
                          <a routerLink="/products/shopping-cart">
                            <i class="fa fa-cart-plus wt-icon-box-xs"></i>
                          </a>
                          <a class="mfp-link" routerLink="/products/wish-list">
                            <i class="fa fa-heart wt-icon-box-xs"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="wt-info text-center">
                      <div class="p-a20 bg-white">
                        <h3 class="wt-title">
                          <a routerLink="/products/product-detail">{{
                            object.title
                          }}</a>
                        </h3>
                        <span class="price">
                          <ins>
                            <span
                              ><span class="Price-currencySymbol">$ </span
                              >{{ object.price }}</span
                            >
                          </ins>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SECTION CONTENT END -->

    <!-- SECTION CONTENT START -->
    <!-- <app-section-featured-products
      [data]="featured"
    ></app-section-featured-products> -->
    <!-- SECTION CONTENT END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>
