<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header2></app-section-header2>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- ABOUT SECTION START -->
        <app-section-about1 [data]="about"></app-section-about1>     
        <!-- ABOUT SECTION  SECTION END --> 

        <!-- PROJECTS SECTION START -->
        <app-section-projects2 [data]="projects" overlay="0"></app-section-projects2>
        <!-- PROJECTS SECTION  SECTION END -->
        
        <!-- COUNTER SECTION START -->
        <app-section-counter2 [data]="counter" bgplain="0"></app-section-counter2>
        <!-- COUNTER SECTION  SECTION END -->              

        <!-- VISION SECTION START -->
        <app-section-vision1 [data]="vision"></app-section-vision1>
        <!-- VISION SECTION END --> 
        
        <!-- SELF INTRO SECTION START -->
        <app-section-self-intro1 [data]="selfIntro"></app-section-self-intro1> 
        <!-- SELF INTRO SECTION  END -->
        
        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1 [data]="clients"></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->                
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>
