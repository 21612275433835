<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header5></app-section-header5>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t80 p-b50 bg-white">
      <div class="container">
        <div class="section-content">
          <p>
            At Flarici (&ldquo;Flarici,&rdquo; &ldquo;we,&rdquo;
            &ldquo;our,&rdquo; or &ldquo;us&rdquo;), we use cookies and similar
            technologies to enhance your experience on our website. This Cookies
            Policy explains what cookies are, how we use them, and your choices
            regarding their usage.
          </p>
          <h3>1. What Are Cookies?</h3>
          <p>
            Cookies are small text files that are stored on your device when you
            visit our website. They help us remember your preferences, improve
            website functionality, and gather information to provide a better
            user experience. Cookies do not typically contain personal data but
            may identify your device.
          </p>
          <h3>2. Categories of Cookies We Use</h3>
          <p style="margin: 0">We use the following categories of cookies:</p>
          <ul style="margin-left: 20px">
            <li>
              <strong><strong>Essential Cookies: </strong></strong>These enable
              the basic functionality of our website, such as its navigability
              or access to secure areas.
            </li>
            <li>
              <strong><strong>Performance Cookies: </strong></strong>These help
              us understand how visitors are interacting with our website to
              improve the functionality of this and optimize user experience.
            </li>
            <li>
              <strong><strong>Functional Cookies: </strong></strong>These
              cookies remember your preferences, for instance, your language or
              region and provide you with a customized experience.
            </li>
            <li>
              <strong><strong>Analytics Cookies: </strong></strong>These cookies
              collect anonymous data to help us analyze website usage and
              performance.
            </li>
          </ul>
          <h3>3. How We Use Cookies</h3>
          <p style="margin: 0">We use cookies to:</p>
          <ul style="margin-left: 20px">
            <li>Ensure our website operates effectively and securely.</li>
            <li>
              Understand visitor preferences and improve website performance.
            </li>
            <li>Offer a more tailored and seamless user experience.</li>
          </ul>
          <h3>4. Managing Cookies</h3>
          <p>
            You can manage or disable cookies through your browser settings.
            Many browsers allow you to refuse or delete cookies, but doing so
            may limit some functionalities of our website.
          </p>
          <h3>5. Third-Party Cookies</h3>
          <p>
            We do not use third-party cookies for marketing or advertising
            purposes. Any cookies utilized on our website are strictly intended
            to improve your browsing experience.
          </p>
          <h3>6. Updates to This Policy</h3>
          <p>
            We may update this Cookies Policy from time to time. Any changes
            will be posted on this page, so we encourage you to review it
            periodically.
          </p>
          <h3>7. Contact Us</h3>
          <p>
            If you have questions about this Cookies Policy or our use of
            cookies, please reach out to us at info&#64;flarici.com.
          </p>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<!-- <app-loader></app-loader> -->
