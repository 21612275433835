import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-faq',
  templateUrl: './page-faq.component.html',
  styleUrls: ['./page-faq.component.css'],
})
export class PageFaqComponent implements OnInit {
 constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle('Frequently Asked Questions | Flarici');
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        "Find answers to commonly asked questions about Flarici's gardening and landscaping services. We are here for you at every step.",
    });
  }

  banner = {
    background: 'assets/images/banner/8.jpg',
    title: 'FAQs',
    crumb: 'FAQs',
  };

  faqs = [
    {
      q: 'How can I make my grass grow thicker?',
      a: '<p> An effective way to grow your grass thicker is by regularly fertilizing, mowing, and properly watering. Aerate the soil reduces compaction and improves root growth.</p>',
    },
    {
      q: 'What are the benefits of mulching in the garden?',
      a: 'Some of the benefits of mulching include suppressing weeds, improving soil quality, and retaining moisture to help your plants thrive.',
    },
    {
      q: 'How do I prevent weeds from growing in my garden?',
      a: 'Regularly apply mulch and hand-pull weeds. On the other hand, the application of natural weed killers (vinegar) is very effective in preventing weeds.',
    },
    {
      q: 'When should I water my plants to keep them nourished?',
      a: 'Late in evening or early in the morning is the perfect time to water your plants to reduce evaporation. Ensure the soil is moist, but not waterlogged.',
    },
    {
      q: 'How can I protect my plants from pests naturally?',
      a: 'Using natural repellents (neem oil) can help protect plants. On the other hand, practicing crop rotation and introducing beneficial insects are effective strategies.',
    },
  ];
}
