import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-blog-grid',
  templateUrl: './page-blog-grid.component.html',
  styleUrls: ['./page-blog-grid.component.css'],
})
export class PageBlogGridComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle('Gardening tips & landscaping ideas | Flarici');
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Read the Flarici blog for gardening tips, landscaping ideas, and professional advice to make your outdoors shine. ',
    });
  }

  banner = {
    background: 'assets/images/banner/1.jpg',
    title: 'Latest Blogs',
    crumb: 'Latest Blogs',
  };

  blogs = [
    {
      image: 'assets/images/blog/default/portrait/1.jpg',
      field: 'Materials',
      date: 'July 28, 2021',
      comments: '24',
      url: '/the-mental-and-physical-benefits-of-gardening',
      title: 'The Mental and Physical Benefits of Gardening',
    },
    {
      image: 'assets/images/blog/default/portrait/2.jpg',
      field: 'Technology',
      date: 'July 28, 2021',
      comments: '24',
      url: '/the-role-of-hardscaping-in-beautiful-landscape-design',
      title: 'The Role of Hardscaping in Beautiful Landscape Design',
    },
  ];
}
