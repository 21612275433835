<div class="section-full p-t80 p-b50 bg-gray bg-cover" style="background-image:url(assets/images/background/bg-12.jpg);">
    <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END--> 

       <div class="section-content">
            <div class="owl-carousel h3-project-slider  mfp-gallery">
            <!-- COLUMNS 1 --> 
            <div class="item" *ngFor="let object of data.services">
                <div class="line-filter-outer">
                    <div class="line-filter-media">
                         <img src="{{object.image}}" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                                <!-- <h3 class="m-tb0 h-category">{{object.type}}</h3> -->
                                <p>{{object.description}}</p>
                                <!-- <a href="{{object.detailImage}}" class="mfp-link"><i class="fa fa-search-plus"></i></a> -->
                            </div>
                        </div>     
                    </div>
                
                    <div class="line-filter  bg-white p-a30">
                        <div class="filter-content">
                            <h3 class="m-t0 m-b20 text-uppercase">{{object.title}}</h3>
                            <a [routerLink]="object.pageLink" class="site-button-link">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
    

        </div>
                                                                        
       </div>
   
   </div>
</div>