<!-- <div class="page-wraper"> -->
<!-- HEADER START -->
<app-section-header5></app-section-header5>
<!-- HEADER END -->

<!-- CONTENT START -->
<div class="page-content">
  <!-- INNER PAGE BANNER -->
  <app-section-inner-banner [data]="banner"></app-section-inner-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SERVICE DETAIL SECTION START -->
  <div class="section-full p-t80 p-b50 bg-white">
    <div class="section-content">
      <div class="container">
        <div class="wt-box services-etc m-b30">
          <div class="wt-media m-b30"
            style="display: flex; justify-content: center;"
          >
            <img src="assets/images/services/large/pic5.jpg" alt="" />
          </div>
          <div class="wt-info">
            <p>
              Do you want to maintain proper shape of your garden throughout the
              year? Then take our seasonal clean-up and maintenance service. We
              trim overgrown plants, remove debris, and prepare your landscape
              for every season. With regular maintenance, we keep your outdoor
              spaces hale and hearty spaces. Enjoy your garden to the fullest at
              any time of the year.
            </p>
          </div>
        </div>

        <div>
          <h2>FAQs</h2>
          <div class="wt-accordion acc-bg-gray" id="accordion5">
            <div class="panel wt-panel">
              <div class="acod-head acc-actives">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne5"
                    data-parent="#accordion5"
                  >
                    What is included in your seasonal clean-up and maintenance
                    service?<span class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne5" class="acod-body collapse show bg-gray">
                <div class="acod-content p-a15">
                  This service includes trimming overgrown plants, removing
                  fallen leaves, preparing your outdoor space for the upcoming
                  season, and clearing debris.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseTwo5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    How often should I schedule seasonal cleanup for my
                    garden?<span class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseTwo5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  Typically, you should schedule clean-up service at the start
                  of each season (winter, fall, summer, and spring) to help your
                  garden adapt to the changing weather.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseThree5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    Is plant well-being improved by seasonal maintenance?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseThree5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  Your plant will thrive with our exclusive seasonal maintenance
                  service. Our service improving air circulation and removing
                  damaged or dead foliage, ensuring vibrant and strong growth
                  throughout the year.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FOOTER START -->
<app-section-footer1></app-section-footer1>
<!-- FOOTER END -->
<!-- </div> -->
