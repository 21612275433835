<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header5></app-section-header5>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t80 p-b50 bg-white">
      <div class="container">
        <div class="section-content">
          <p>
            At Flarici ("Flarici," "we," "our," or "us"), your privacy is
            important to us. This Privacy Policy describes how we collect, use,
            disclose, and transfer your personal data in line with the
            requirements of the Personal Data (Privacy) Ordinance of Hong Kong.
            By accessing our website and services, you agree to this Privacy
            Policy.
          </p>
          <h3>1. Definition of Personal Data</h3>
          <p>
            Personal data is information that personally identifies you, such as
            your name and contact details or any other information shared when
            accessing our website or services.
          </p>
          <h3>2. What Information Do We Collect</h3>
          <p style="margin: 0">
            The following are how we get the personal data:
          </p>
          <ul style="margin-left: 20px">
            <li>
              Information you provide: &nbsp;Your name, your e-mail address,
              your telephone number, among others supplied inquiries.
            </li>
            <li>
              Automatically Collected Information: When you visit our website,
              we may collect non-identifiable data such as browser type, pages
              visited, and time spent on the website. This data helps us improve
              user experience.
            </li>
          </ul>
          <h3>3. How We Use Your Information</h3>
          <p style="margin: 0">The information we collect is used to:</p>
          <ul style="margin-left: 20px">
            <li>
              Provide, maintain, and improve our gardening and landscaping
              services.
            </li>
            <li>
              Respond to your inquiries and communicate important updates.
            </li>
            <li>Personalize your website experience.</li>
          </ul>
          <p>
            We do not use your personal data for marketing purposes unless you
            provide explicit consent.
          </p>
          <h3>4. Data Sharing and Disclosure</h3>
          <p style="margin: 0">
            We do not share, sell, or rent your personal data to third parties
            but under the following circumstances:
          </p>
          <ul style="margin-left: 20px">
            <li>
              This is if we are required by law or regulatory authorities in
              compliance with the PDPO.
            </li>
            <li>
              To trusted service providers who help us operate our website or
              deliver services, provided they adhere to strict confidentiality
              obligations.
            </li>
          </ul>

          <h3>5. Children's Use of the Website</h3>
          <p>
            Flarici's website is not designed for children under 13 years of
            age. We do not knowingly collect personal data from children. In
            case a child has provided personal data we will delete such data as
            soon as possible. Parents can contact us directly to remove any
            data.
          </p>
          <h3>6. Data Security</h3>
          <p>
            We shall apply suitable technical and organisational measures so
            that your personal data is not accessed, altered, disclosed or
            destroyed without authorization. While we make every effort to
            secure our systems, no system can be guaranteed to be completely
            safe. Therefore, we cannot assure absolute security for your data.
          </p>
          <h3>7. Rights Under PDPO</h3>
          <p style="margin: 0">According to the PDPO you have:</p>
          <ul style="margin-left: 20px">
            <li>Right of access and rectification</li>
            <li>Opting-out</li>
            <li>Objection</li>
          </ul>
          <h3>8. Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with a revised effective date. We
            encourage you to review this policy periodically.
          </p>
          <h3>9. Contact Us</h3>
          <p>
            Questions, concerns, or requests regarding this Privacy Policy may
            be directed to info&#64;flarici.com.
          </p>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<!-- <app-loader></app-loader> -->
