<!-- <div class="page-wraper"> -->
<!-- HEADER START -->
<app-section-header5></app-section-header5>
<!-- HEADER END -->

<!-- CONTENT START -->
<div class="page-content">
  <!-- INNER PAGE BANNER -->
  <app-section-inner-banner [data]="banner"></app-section-inner-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SERVICE DETAIL SECTION START -->
  <div class="section-full p-t80 p-b50 bg-white">
    <div class="section-content">
      <div class="container">
        <div class="wt-box services-etc m-b30">
          <div class="wt-media m-b30" 
            style="display: flex; justify-content: center;"
          >
            <img src="assets/images/services/large/pic2.jpg" alt="" />
          </div>
          <div class="wt-info">
            <p>
              Want to transform your outdoor space? Try our exceptional
              landscape design and planning service for a functional and
              beautiful garden area. Our specialty is creating customized
              designs according to the preferences and styles of the customer,
              considering factors such as plant selection, aesthetics, and
              layout. Whether for entertainment or relaxation, we ensure to
              deliver you a visually appealing landscape that is easy to
              maintain as well.
            </p>
          </div>
        </div>

        <div>
          <h2>FAQs</h2>
          <div class="wt-accordion acc-bg-gray" id="accordion5">
            <div class="panel wt-panel">
              <div class="acod-head acc-actives">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne5"
                    data-parent="#accordion5"
                  >
                    What does our landscape design service include?<span
                      class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne5" class="acod-body collapse show bg-gray">
                <div class="acod-content p-a15">
                  We prioritize understanding your landscape design needs
                  through an initial consultation, followed by design creation,
                  detailed planning, and site analysis.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseTwo5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    Can you design for small spaces?<span class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseTwo5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  Certainly, we specialize in creating innovative designs for
                  all types and sizes of spaces, whether it's a challenging
                  terrain or a small garden.
                </div>
              </div>
            </div>
            <div class="panel wt-panel">
              <div class="acod-head">
                <h4 class="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseThree5"
                    class="collapsed"
                    data-parent="#accordion5"
                  >
                    What is the typical timeframe for completing a landscape
                    design?<span class="indicator"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </a>
                </h4>
              </div>
              <div id="collapseThree5" class="acod-body collapse bg-gray">
                <div class="acod-content p-a15">
                  The timeline depends on the complexity and size of the
                  project. On average, a landscape design takes a few weeks.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FOOTER START -->
<app-section-footer1></app-section-footer1>
<!-- FOOTER END -->
<!-- </div> -->
