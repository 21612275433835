import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-s-chemical',
  templateUrl: './page-s-chemical.component.html',
  styleUrls: ['./page-s-chemical.component.css'],
})
export class PagePestandweed implements OnInit {
  @Input() loadingurl: any;

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.loadingurl = event.url.split('/')[2];
      });
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle('Our pest and weed control support | Flarici');
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Keep your garden thriving and well-maintained with our safe, targeted, and effective pest and weed control solutions.',
    });
  }

  banner = {
    background: 'assets/images/banner/2.jpg',
    title: 'Pest and weed control',
    crumb: 'Pest and weed control',
  };
}
