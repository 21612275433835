<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header5></app-section-header5>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t80 p-b50 bg-white">
      <div class="container">
        <div class="section-content">
          <p>
            Welcome to Flarici ("Flarici," "we," "our," or "us"). By using our
            gardening and landscaping services, you agree to be bound by our
            Terms and Conditions. Read these terms thoroughly.
          </p>
          <h3>1. Scope of Services</h3>
          <p style="margin: 0">
            Flarici offers gardening and landscaping services aimed at making
            outdoor areas visually beautiful and functional. Some, though not
            all, examples of such services are:
          </p>
          <ul style="margin-left: 20px">
            <li>Landscape design and planning</li>
            <li>Gardening and upkeep.</li>
            <li>Plant, tree, and shrub installation.</li>
            <li>Lawn maintenanceservices such as mowing and fertilization.</li>
          </ul>
          <h3>2. Client Responsibilities</h3>
          <p>
            Clients will provide all necessary information about site
            conditions, access, and restrictions to the completion of services.
            It is also a client's responsibility to make clear access to the
            worksite and inform us of any change to the agreed scope of work in
            time.
          </p>
          <h3>3. Pricing and Payment</h3>
          <p>
            All service costs will be communicated to the client prior to the
            start of the project. Payments must be made as specified in the
            issued invoice. Late payments may result in service delays or
            suspension.
          </p>
          <h3>4. Warranties and Liability</h3>
          <p>
            Flarici aims for quality work and customer satisfaction. We,
            however, cannot guarantee the survival of plants and vegetation
            because of uncontrollable factors such as weather conditions, pests,
            or improper maintenance&nbsp;after installation.
          </p>
          <p>
            We are not liable for any damages caused by delays or interruptions
            due to circumstances outside our control including acts of nature,
            labor disputes or unforeseen site conditions.
          </p>
          <h3>5. Data Privacy and Security</h3>
          <p>
            We safeguard your privacy and respect and protect your personal
            information. Only the information essential for Flarici to deliver
            services efficiently is collected.
          </p>
          <p>
            <strong><strong>No sharing of data:</strong></strong
            >&nbsp;Your private information is not shared at any cost with
            third-party services unless it is mandatorily required by law.
          </p>
          <p>
            <strong><strong>Secure data handling:</strong></strong
            >&nbsp;Your data is stored with proper maintenance and is strictly
            for service delivery, customer interactions, and billing purposes.
          </p>
          <h3>6. Alteration to Services</h3>
          <p>
            We reserve the right to modify, suspend, or discontinue any aspect
            of our services without prior notice. Significant changes that
            affect an ongoing project will be communicated directly to the
            client.
          </p>
          <h3>7. Termination of Services</h3>
          <p>
            Either party may terminate the service agreement with prior written
            notice. Clients will be responsible for payment of any services
            rendered up to the date of termination.
          </p>
          <h3>8. Governing Law</h3>
          <p>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of Hong Kong
          </p>
          <h3>9. Contact Information</h3>
          <p>
            For questions or concerns about these terms, you can contact
            Flarici through info&#64;flarici.com.
          </p>
          <p>
            Thank you for choosing Flarici. We look forward to creating and
            maintaining beautiful outdoor spaces for you.
          </p>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<!-- <app-loader></app-loader> -->
