<div
  class="section-full  p-t80 p-b50 bg-no-repeat {{ data.bgClass }}"
  style="background-image:url({{ data.bgImage }})"
>
  <div class="container">
    <!-- TITLE START-->
    <div class="section-head center wt-small-separator-outer">
      <div class="wt-small-separator site-text-primary">
        <div class="sep-leaf-left"></div>
        <div>{{ data.title }}</div>
        <div class="sep-leaf-right"></div>
      </div>
      <h2>{{ data.subtitle }}</h2>
    </div>
    <!-- TITLE END-->

    <div class="testimonial-2-content-outer">
      <div class="testimonial-2 {{ whiteBox == '1' ? 'bg-white' : 'bg-gray' }}">
        <div class="testimonial-content">
          <p>
            Our specialty is to provide a wide range of lawn and gardening
            services, including but not limited to fertilizing, pest control,
            mowing, pruning, and many more, to keep your garden well-maintained
            and beautiful year-round.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
