<header class="site-header header-style-1 mobile-sider-drawer-menu">
  <!-- SITE Search -->
  <div id="search-toggle-block">
    <div id="search">
      <form
        role="search"
        id="searchform"
        action="/search"
        method="get"
        class="radius-xl"
      >
        <div class="input-group">
          <input
            class="form-control"
            value=""
            name="q"
            type="search"
            placeholder="Type to search"
          />
          <span class="input-group-append"
            ><button type="button" class="search-btn">
              <i class="fa fa-search"></i></button
          ></span>
        </div>
      </form>
    </div>
  </div>

  <div class="container header-middle clearfix">
    <div class="logo-header">
      <div class="logo-header-inner logo-header-one">
        <a routerLink="/">
          <img id="headLogoId" src="assets/images/logo-4.png" alt="" />
        </a>
      </div>
    </div>

    <!-- <div class="header-info">
      <ul>
        <li>
          <div class="icon-md">
            <span class="icon-cell"><i class="flaticon-trophy"></i></span>
          </div>
          <div class="icon-content">
            <strong>The Best Industrial</strong>
            <span>Solution Provider</span>
          </div>
        </li>
        <li>
          <div class="icon-md">
            <span class="icon-cell"><i class="flaticon-stamp"></i></span>
          </div>
          <div class="icon-content">
            <strong>Certified Company</strong>
            <span>ISO 9001-2021</span>
          </div>
        </li>
      </ul>
    </div> -->
  </div>

  <div class="sticky-header main-bar-wraper navbar-expand-lg">
    <div class="main-bar">
      <div class="container clearfix">
        <!-- NAV Toggle Button -->
        <button
          id="mobile-side-drawer"
          data-target=".header-nav"
          data-toggle="collapse"
          type="button"
          class="navbar-toggler collapsed"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar icon-bar-first"></span>
          <span class="icon-bar icon-bar-two"></span>
          <span class="icon-bar icon-bar-three"></span>
        </button>

        <!-- MAIN Vav -->
        <div
          class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center"
        >
          <ul class="nav navbar-nav">
            <li
              [routerLinkActive]="'active'"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a routerLink="/">Home</a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/about">About Us</a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/services">Services</a>
            </li>
            <li routerLinkActive="active"><a routerLink="/blogs">Blogs</a></li>
            <li routerLinkActive="active"><a routerLink="/faqs">FAQs</a></li>
            <li routerLinkActive="active">
              <a routerLink="/contact">Contact Us</a>
            </li>
          </ul>
        </div>

        <!-- <div class="header-nav-request">
          <a class="contact-slide-show"
            >Request a Quote <i class="fa fa-angle-right"></i
          ></a>
        </div> -->
      </div>
    </div>
  </div>
</header>
