<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header5></app-section-header5>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t80 p-b50 bg-white">
      <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
          <div class="wt-small-separator site-text-primary">
            <div class="sep-leaf-left"></div>
            <div>Frequently Asked Questions</div>
            <div class="sep-leaf-right"></div>
          </div>
          <h2>Commonly Asked Question</h2>
        </div>
        <!-- TITLE END-->

        <div class="section-content">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <!-- Accordian -->
              <div
                class="wt-accordion acc-bg-gray faq-accorfion"
                id="accordion5"
              >
                <div
                  class="panel wt-panel"
                  *ngFor="let object of faqs; let i = index"
                >
                  <div class="acod-head {{ i == 0 ? 'acc-actives' : '' }}">
                    <h3 class="acod-title">
                      <a
                        data-toggle="collapse"
                        href="#collapse{{ i }}5"
                        class="{{ i == 0 ? '' : 'collapsed' }}"
                        data-parent="#accordion5"
                      >
                        {{ object.q }}
                        <span class="indicator"
                          ><i class="fa fa-plus"></i
                        ></span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapse{{ i }}5"
                    class="acod-body collapse {{ i == 0 ? 'show' : '' }}"
                  >
                    <div
                      class="acod-content p-a20 bg-gray"
                      [innerHTML]="object.a"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<!-- <app-loader></app-loader> -->
