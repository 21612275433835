import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-s-civil',
  templateUrl: './page-s-civil.component.html',
  styleUrls: ['./page-s-civil.component.css'],
})
export class PageSeasonalcleanup implements OnInit {
  @Input() loadingurl: any;

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.loadingurl = event.url.split('/')[2];
      });
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle(
      'Seasonal cleanup and maintenance process | Flarici'
    );
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Prepare your garden to tackle seasonal challenges with our clean-up services and keep it vibrant year-round.',
    });
  }

  banner = {
    background: 'assets/images/banner/2.jpg',
    title: 'Seasonal cleanup and maintenance',
    crumb: 'Seasonal cleanup and maintenance',
  };
}
