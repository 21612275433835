<div
  class="slider-outer"
  style="position: relative; width: 100%; height: 70vh; overflow: hidden"
>
  <!-- BACKGROUND IMAGE -->
  <img
    src="assets/images/main-slider/slider5/slide1.jpg"
    alt="Background Image"
    style="position: absolute; width: 100%; height: 100%; object-fit: cover"
  />

  <!-- TITLE -->
  <div>
    <h1
      style="
        position: absolute;
        top: 25%;
        left: 10%;
        color: #fff;
        font-size: 120px;
        line-height: 100px;
        font-weight: 600;
        font-family: 'Teko', sans-serif;
      "
    >
      Innovative Landscaping for the Modern Home
    </h1>
  </div>

  <!-- SUBTITLE -->
  <div>
    <h3
      style="
        position: absolute;
        top: 20%;
        left: 10%;
        color: #fff;
        font-size: 38px;
        line-height: 38px;
        font-weight: 500;
        font-family: 'Teko', sans-serif;
      "
    >
      Transform your outdoor space today
    </h3>
  </div>
</div>
