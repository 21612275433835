import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-about1',
  templateUrl: './page-about1.component.html',
  styleUrls: ['./page-about1.component.css'],
})
export class PageAbout1Component implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Set the page title
    this.titleService.setTitle(
      'Professional landscaping & gardening | Flarici'
    );
    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content:
        'Get to know Flarici, your reliable landscaping and gardening experts. We design aesthetically pleasing outdoor spaces to match your requirements.',
    });
  }

  banner = {
    background: 'assets/images/banner/3.jpg',
    title: 'About us',
    crumb: 'About us',
  };

  about = {
    title: 'Explore our journey',
    subtitle: 'Our gardening excellence differentiates us from our competitors',
    image1: 'assets/images/colarge/s2.jpg',
    image2: 'assets/images/colarge/s1.jpg',
    since: '2015',
    description:
      'We are a newly launched gardening and landscaping firm in the heart of Hong Kong. We aim to gain immense reputation through our eco-conscious gardening services, exceptional customer support, and attention to detail. Our goal is to transform your outdoor space with our high-quality, personalized services.',
    services: [
      {
        value:
          'You will be completely satisfied with our quality gardening services',
      },
      {
        value: 'Professional craftsmanship backed by personalized guidance',
      },
      {
        value: 'Give your barren land a ray of hope filled with lush greenery',
      },
    ],
  };

  services = {
    title: 'Welcome to Flarici',
    subtitle: 'Seasonal Garden Solutions Tailored for Your Unique Needs',
    services: [
      {
        title1: 'Soil fertilization and',
        title2: 'soil revitalization',
        description:
          'This service is very important for plant growth and improves soil structure',
        icon: 'assets/images/icons/1.png',
        count: '01',
        pageLink: 'soil-fertilization-and-soil-revitalization',
      },
      {
        title1: 'Mulching and ',
        title2: 'composting',
        description:
          'To enrich the soil and retain moisture, cover it with organic matter',
        icon: 'assets/images/icons/2.png',
        count: '02',
        pageLink: 'mulching-and-composting',
      },
      {
        title1: 'Pest and weed',
        title2: 'control',
        description:
          'Protect your plants from weeds and pests with our service',
        icon: 'assets/images/icons/3.png',
        count: '03',
        pageLink: 'pest-and-weed-control',
      },
      {
        title1: 'Seasonal cleanup',
        title2: 'and maintenance',
        description:
          'This service ensures optimal plant growth through proper maintenance',
        icon: 'assets/images/icons/4.png',
        count: '04',
        pageLink: 'seasonal-cleanup-and-maintenance',
      },
      {
        title1: 'Landscape design',
        title2: 'and planning',
        description:
          'Organizing and designing gardens for functional use and aesthetic appeal',
        icon: 'assets/images/icons/5.png',
        count: '05',
        pageLink: 'landscape-design-and-planning',
      },
      {
        title1: 'Tree and',
        title2: 'shrub pruning',
        description:
          'Trimming trees and shrubs for a better, structural, and vigorous appearance of your garden',
        icon: 'assets/images/icons/6.png',
        count: '06',
        pageLink: 'tree-and-shrub-pruning',
      },
    ],
  };

  video = {
    title: 'All Solutions',
    subtitle: 'Get A Solution For All Industries',
    videolink: 'https://www.youtube.com/watch?v=fgExvIUYg5w',
    solutions: [
      {
        count: '35',
        title: 'Projects Completed',
      },
      {
        count: '1435',
        title: 'Work Employed',
      },
      {
        count: '750',
        title: 'Work facilities',
      },
      {
        count: '26',
        title: 'Winning Awards',
      },
    ],
    description: {
      quote:
        'Many of Our SELF registered employees are requested an main preferred temporary staff when all service',
      quoteby: 'Farnandoz Biki, CEO',
    },
  };

  team = {
    title: 'Our Experts',
    subtitle: 'We will serve you with the best of our capacity by expert team',
    bgImage: 'assets/images/background/bg-map.png',
    members: [
      {
        image: 'assets/images/team/pic1.jpg',
        name: 'Malcolm Franzcrip',
        post: 'Contractor',
      },
      {
        image: 'assets/images/team/pic2.jpg',
        name: 'Froster Collings',
        post: 'Contractor',
      },
      {
        image: 'assets/images/team/pic3.jpg',
        name: 'Melena Marshall',
        post: 'Contractor',
      },
    ],
  };

  clients = {
    bgcolor: 'bg-gray',
    clients: [
      'assets/images/client-logo/w1.png',
      'assets/images/client-logo/w2.png',
      'assets/images/client-logo/w3.png',
      'assets/images/client-logo/w4.png',
      'assets/images/client-logo/w5.png',
      'assets/images/client-logo/w6.png',
      'assets/images/client-logo/w1.png',
      'assets/images/client-logo/w2.png',
      'assets/images/client-logo/w3.png',
      'assets/images/client-logo/w4.png',
      'assets/images/client-logo/w5.png',
      'assets/images/client-logo/w6.png',
      'assets/images/client-logo/w1.png',
      'assets/images/client-logo/w2.png',
      'assets/images/client-logo/w3.png',
      'assets/images/client-logo/w4.png',
      'assets/images/client-logo/w5.png',
      'assets/images/client-logo/w6.png',
    ],
  };

  vision = {
    title: 'Why choose us',
    subtitle: 'Check out our services to make your garden a masterpiece',
    description:
      "Our professionalism and services across Hong Kong ensure timeliness, cost efficiency and compliance adherence required to ensure your timelines are met. Serving with experience and expertise in multiple industries, as one of the World's leading Corporation!",
    videoLink: 'https://www.youtube.com/watch?v=fgExvIUYg5w',
    quote:
      'Many of Our SELF registered employees are requested an main preferred temporary staff when all service',
    quoteBy: 'Farnandoz Biki, CEO',
    title2: 'Vision & Mission Statement',
    subtitle2: 'Serving Impressive List Of Long Term Clients!',
    description2:
      "Our professionalism and services across Hong Kong ensure timeliness, cost efficiency and compliance adherence required to ensure your timelines are met. Serving with experience and expertise in multiple industries, as one of the World's leading Corporation!",
    title3: 'Vision & Mission Statement',
    subtitle3: 'Serving Impressive List Of Long Term Clients!',
    description3:
      "Our professionalism and services across Hong Kong ensure timeliness, cost efficiency and compliance adherence required to ensure your timelines are met. Serving with experience and expertise in multiple industries, as one of the World's leading Corporation!",
  };
}
