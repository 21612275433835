<div class="blog-post date-style-2">
  <div class="wt-post-media wt-img-effect zoom-slow">
    <a [routerLink]="object.url"><img src="{{ object.image }}" alt="" /></a>
  </div>
  <div class="wt-post-info bg-white p-t30">
    <!-- <div class="wt-post-meta ">
            <ul>
                <li class="post-category"><span>{{object.field}}</span> </li>
                <li class="post-date">{{object.date}}</li>
                <li class="post-comment">{{object.comments}} Comment</li>
            </ul>
        </div>                                  -->
    <div class="wt-post-title">
      <!-- <h3 *ngIf="titleLink == 0" class="post-title">{{ object.title }}</h3> -->
      <h3 *ngIf="titleLink == 0" class="post-title">
        <a
          [routerLink]="object.url"
          class="site-text-secondry"
          >{{ object.title }}</a
        >
      </h3>
    </div>
    <div class="wt-post-readmore">
      <a [routerLink]="object.url" class="site-button-link black">Read More</a>
    </div>
  </div>
</div>
